<!--https://gsr.dev/material2-carousel/-->
<!--https://github.com/gbrlsnchs/material2-carousel-->
<div class="wrapper">
  <div class="columns mb-0">
    <div class="column is-full p-0">
      <mat-carousel timings="250ms ease-in"
                    [autoplay]="true"
                    interval="6000"
                    color="accent"
                    maxWidth="auto"
                    slides="5"
                    [loop]="true"
                    [hideArrows]="false"
                    [hideIndicators]="true"
                    [useKeyboard]="true"
                    [useMouseWheel]="false"
                    [maintainAspectRatio]="true"
                    [proportion]="29"
                    orientation="ltr">
        <mat-carousel-slide #matCarouselSlide
                            *ngFor="let slide of sliders; let i = index"
                            overlayColor="#00000040"
                            [hideOverlay]="true">
          <a [attr.href]="slide.url" target="_blank" *ngIf="slide.is_external && slide.url">
           <!-- <img [src]="_cmsApiUrl + \\slide.image.url"> -->
            <img [src]="slide.image.url">
          </a>
          <a [routerLink]="slide.url" *ngIf="!slide.is_external">
            <!-- <img [src]="_cmsApiUrl + slide.image.url"> -->
            <img [src]="slide.image.url">
          </a>
        </mat-carousel-slide>
      </mat-carousel>
    </div>
  </div>
  <div class="columns m-2">
    <div class="column is-8" *ngIf="mission">
      <div class="box mb-0" style="height:100%">
        <h3 class="title is-4">
          {{mission.title}}
        </h3>
        <div [innerHTML]="mission.description | safeHtml">
        </div>
      </div>
    </div>
    <div class="column" *ngIf="ackFacility">
      <div class="box" style="height:100%">
        <h3 class="title is-4">
          {{ackFacility.title}}
        </h3>
        <div [innerHTML]="ackFacility.description | safeHtml">
        </div>
      </div>
    </div>
  </div>
  <div class="columns mt-6 mb-6 is-mobile is-centered" style="margin-left:11px;  max-width:99%; margin:auto">

    <div class="column is-one-quarter">
      <div class="buttons are-large">
        <a routerLink="/new-user" class="button is-fullwidth is-link">
          New user
        </a>
      </div>
    </div>
    <div class="column is-one-quarter">
      <div class="buttons are-large">
        <a routerLink="/analyzers" class="button is-fullwidth is-link">
          Analyzers
        </a>
      </div>
    </div>
    <div class="column is-one-quarter">
      <div class="buttons are-large">
        <a routerLink="/cell-sorters" class="button is-fullwidth is-link">
          Cell Sorters
        </a>
      </div>
    </div>

    <div class="column is-one-quarter">
      <div class="buttons are-large">
        <a routerLink="/sort-calculator" class="button is-fullwidth is-link">
          Sort Calculator
        </a>
      </div>
    </div>
  </div>
  <div class="columns m-2">
    <div class="column is-8">



      <div class="box" style="height:100%">


        <div class="m-b-sm m-t-sm">
          <figure class="image">
            <a target="_blank" href="https://www.mskcc.org/videos/inside-msk-flow-cytometry-core-facility">
              <img style="width: 60%; margin:auto" src="../../assets/images/FCCFTeam.png">
              <fa-icon class="faPlay" [icon]="faPlay"  style=" position: absolute; top: 40%; left: 48%; color: white; font-size: 50px;"></fa-icon>
            </a>
          </figure>
        </div>

        <!--<accordion [contents]="contents" [titleHeight]="70"
                   [loadContentTitleTemplate]="true" [contentTitleTemplate]="contentTitleTemplate"
                   [loadContentTemplate]="true" [contentTemplate]="contentTemplate"></accordion>-->
      </div>
    </div>
    <div class="column">
      <div class="box" style="height:100%">
        <iframe src="twitter" style="width:100%; min-height:500px"></iframe>
      </div>
    </div>
  </div>
</div>

<ng-template #contentTitleTemplate let-content="content">
  <div>
    <p [style.color]="content.title_color ? content.title_color.code : ''">
      {{content.title}}
    </p>
    <p style="color:rgb(255, 153, 0)" class="title is-7">
      {{content.datetime | date:'EE, MMM d, yyyy'}}
      <ng-container *ngIf="content.sub_title">
        @ {{content.sub_title}}
      </ng-container>
    </p>
  </div>
</ng-template>

<ng-template #contentTemplate let-content="content">
  <div class="m-b-sm m-t-sm" *ngIf="content.description">
    <div [innerHTML]="content.description | safeHtml">
    </div>
  </div>
  <div class="m-b-sm m-t-sm" *ngIf="content.images.length">
    <!--- <img [src]="_cmsApiUrl + getImageURL(content)" [alt]="content.title"> -->
    <img [src]="content.images[0].url" [alt]="content.title">
  </div>
</ng-template>
