import { Routes } from '@angular/router';
import {
  BiosafetyComponent,
  CancerCenterSupportComponent,
  EducationComponent,
  ForOperatorsComponent,
  GoodFlowNotesComponent,
  GuidelinesComponent,
  InstrumentsComponent,
  LabLocationsComponent,
  NewsEventsComponent,
  NewUserComponent,
  PanelDesignComponent,
  SamplePreparationComponent,
  ScienceComponent,
  ServicesComponent,
  TeamComponent,
  TechnologyComponent,
  TrainingComponent,
  TroubleshootingComponent,
  AboutUsComponent,
  ContactUsComponent,
  LegalDisclaimerComponent,
  PrivacyPolicyComponent,
  PublicNoticesComponent,
  SitemapComponent,
  SearchComponent,
  FlowPostItsComponent,
  ProtocolsComponent,
  SortCalculatorComponent,
  FacilityPolicyComponent,
  OnlineToolsComponent,
  AnalyzersComponent,
  CellSortersComponent,
  SortingComponent,
  AnalysisComponent,
  AnalysisSoftwareComponent,
  RatesComponent,
  TrainingClassesComponent,
  TrainingOnAnalyzersComponent,
  TrainingOnCellSortersComponent,
  TrainingWebinarSeriesComponent,
  TrainingExcyteMasteryComponent,
  CoreFacilityRelatedComponent
} from './component.index';
import { PanelDesignResourcesComponent } from './panel-design-resources/panel-design-resources.component';
import { ServicingIndividualLabsComponent } from './servicing-individual-labs/servicing-individual-labs.component';

export const ComponentRoutes: Routes = [

  {
    path: 'analyzers/:firstlevel/:secondlevel/:thirdlevel',
    component: AnalyzersComponent
  },
  {
    path: 'analyzers/:firstlevel/:secondlevel',
    component: AnalyzersComponent
  },
  {
    path: 'analyzers/:firstlevel',
    component: AnalyzersComponent
  },
  {
    path: 'analyzers',
    component: AnalyzersComponent
  },

  {
    path: 'biosafety/:firstlevel/:secondlevel/:thirdlevel',
    component: BiosafetyComponent
  },
  {
    path: 'biosafety/:firstlevel/:secondlevel',
    component: BiosafetyComponent
  },
  {
    path: 'biosafety/:firstlevel',
    component: BiosafetyComponent
  },
  {
    path: 'biosafety',
    component: BiosafetyComponent
  },
  {
    path: 'cancer-center-support',
    component: CancerCenterSupportComponent
  },

  {
    path: 'cellsorters/:firstlevel/:secondlevel/:thirdlevel',
    component: CellSortersComponent
  },
  {
    path: 'cell-sorters/:firstlevel/:secondlevel',
    component: CellSortersComponent
  },
  {
    path: 'cell-sorters/:firstlevel',
    component: CellSortersComponent
  },
  {
    path: 'cell-sorters',
    component: CellSortersComponent
  },


  {
    path: 'education',
    component: EducationComponent
  },
  {
    path: 'for-operators',
    component: ForOperatorsComponent
  },

  {
    path: 'guidelines/:firstlevel/:secondlevel/:thirdlevel',
    component: GuidelinesComponent
  },
  {
    path: 'guidelines/:firstlevel/:secondlevel',
    component: GuidelinesComponent
  },
  {
    path: 'guidelines/:firstlevel',
    component: GuidelinesComponent
  },
  {
    path: 'good-flow-notes',
    component: GoodFlowNotesComponent
  },

  {
    path: 'guidelines',
    component: GuidelinesComponent
  },
  //{
  //  path: 'instruments/:firstlevel/:secondlevel/:thirdlevel/:fourthlevel',
  //  component: InstrumentsComponent
  //},
  {
    path: 'instruments/:firstlevel/:secondlevel/:thirdlevel',
    component: InstrumentsComponent
  },
  {
    path: 'instruments/:firstlevel/:secondlevel',
    component: InstrumentsComponent
  },
  {
    path: 'instruments/:firstlevel',
    component: InstrumentsComponent
  },
  {
    path: 'instruments',
    component: InstrumentsComponent
  },
  {
    path: 'training-classes/:firstlevel/:secondlevel/:thirdlevel',
    component: TrainingClassesComponent
  },
  {
    path: 'training-classes/:firstlevel/:secondlevel',
    component: TrainingClassesComponent
  },
  {
    path: 'training-classes/:firstlevel',
    component: TrainingClassesComponent
  },
  {
    path: 'training-classes',
    component: TrainingClassesComponent
  },
  {
    path: 'training-on-analyzers/:firstlevel/:secondlevel/:thirdlevel',
    component: TrainingOnAnalyzersComponent
  },
  {
    path: 'training-on-analyzers/:firstlevel/:secondlevel',
    component: TrainingOnAnalyzersComponent
  },
  {
    path: 'training-on-analyzers/:firstlevel',
    component: TrainingOnAnalyzersComponent
  },
  {
    path: 'training-on-analyzers',
    component: TrainingOnAnalyzersComponent
  },

  {
    path: 'training-on-cell-sorters/:firstlevel/:secondlevel/:thirdlevel',
    component: TrainingOnCellSortersComponent
  },
  {
    path: 'training-on-cell-sorters/:firstlevel/:secondlevel',
    component: TrainingOnCellSortersComponent
  },
  {
    path: 'training-on-cell-sorters/:firstlevel',
    component: TrainingOnCellSortersComponent
  },
  {
    path: 'training-on-cell-sorters',
    component: TrainingOnCellSortersComponent
  },

  {
    path: 'training-webinar-series/:firstlevel/:secondlevel/:thirdlevel',
    component: TrainingWebinarSeriesComponent
  },
  {
    path: 'training-webinar-series/:firstlevel/:secondlevel',
    component: TrainingWebinarSeriesComponent
  },
  {
    path: 'training-webinar-series/:firstlevel',
    component: TrainingWebinarSeriesComponent
  },
  {
    path: 'training-webinar-series',
    component: TrainingWebinarSeriesComponent
  },

  {
    path: 'training-excyte-mastery/:firstlevel/:secondlevel/:thirdlevel',
    component: TrainingExcyteMasteryComponent
  },
  {
    path: 'training-excyte-mastery/:firstlevel/:secondlevel',
    component: TrainingExcyteMasteryComponent
  },
  {
    path: 'training-excyte-mastery/:firstlevel',
    component: TrainingExcyteMasteryComponent
  },
  {
    path: 'training-excyte-mastery',
    component: TrainingExcyteMasteryComponent
  },


  {
    path: 'lab-locations/:firstlevel/:secondlevel/:thirdlevel',
    component: LabLocationsComponent
  },
  {
    path: 'lab-locations/:firstlevel/:secondlevel',
    component: LabLocationsComponent
  },
  {
    path: 'lab-locations/:firstlevel',
    component: LabLocationsComponent
  },
  {
    path: 'lab-locations',
    component: LabLocationsComponent
  },
  {
    path: 'news-events/:firstlevel/:secondlevel/:thirdlevel',
    component: NewsEventsComponent
  },
  {
    path: 'news-events/:firstlevel/:secondlevel',
    component: NewsEventsComponent
  },
  {
    path: 'news-events/:firstlevel',
    component: NewsEventsComponent
  },
  {
    path: 'news-events',
    component: NewsEventsComponent
  },
  {
    path: 'new-user/:firstlevel/:secondlevel/:thirdlevel',
    component: NewUserComponent
  },
  {
    path: 'new-user/:firstlevel/:secondlevel',
    component: NewUserComponent
  },
  {
    path: 'new-user/:firstlevel',
    component: NewUserComponent
  },
  {
    path: 'new-user',
    component: NewUserComponent
  },
  {
    path: 'panel-design/:firstlevel/:secondlevel/:thirdlevel',
    component: PanelDesignComponent
  },
  {
    path: 'panel-design/:firstlevel/:secondlevel',
    component: PanelDesignComponent
  },
  {
    path: 'panel-design/:firstlevel',
    component: PanelDesignComponent
  },
  {
    path: 'panel-design',
    component: PanelDesignComponent
  },

  {
    path: 'panel-design-resources/:firstlevel/:secondlevel/:thirdlevel',
    component: PanelDesignResourcesComponent
  },
  {
    path: 'panel-design-resources/:firstlevel/:secondlevel',
    component: PanelDesignResourcesComponent
  },
  {
    path: 'panel-design-resources/:firstlevel',
    component: PanelDesignResourcesComponent
  },
  {
    path: 'panel-design-resources',
    component: PanelDesignResourcesComponent
  },

  {
    path: 'sample-preparation',
    component: SamplePreparationComponent
  },
  {
    path: 'science',
    component: ScienceComponent
  },
  {
    path: 'services/:firstlevel/:secondlevel/:thirdlevel',
    component: ServicesComponent
  },
  {
    path: 'services/:firstlevel/:secondlevel',
    component: ServicesComponent
  },
  {
    path: 'services/:firstlevel',
    component: ServicesComponent
  },
  {
    path: 'services',
    component: ServicesComponent
  },
  {
    path: 'team',
    component: TeamComponent
  },
  {
    path: 'technology/:firstlevel/:secondlevel/:thirdlevel',
    component: TechnologyComponent
  },
  {
    path: 'technology/:firstlevel/:secondlevel',
    component: TechnologyComponent
  },
  {
    path: 'technology/:firstlevel',
    component: TechnologyComponent
  },
  {
    path: 'technology',
    component: TechnologyComponent
  },
  {
    path: 'training/:firstlevel/:secondlevel/:thirdlevel',
    component: TrainingComponent
  },
  {
    path: 'training/:firstlevel/:secondlevel',
    component: TrainingComponent
  },
  {
    path: 'training/:firstlevel',
    component: TrainingComponent
  },
  {
    path: 'training',
    component: TrainingComponent
  },
  {
    path: 'troubleshooting',
    component: TroubleshootingComponent
  },
  {
    path: 'about-us',
    component: AboutUsComponent
  },
  {
    path: 'contact-us',
    component: ContactUsComponent
  },
  {
    path: 'legal-disclaimer',
    component: LegalDisclaimerComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'public-notices',
    component: PublicNoticesComponent
  },
  {
    path: 'sitemap',
    component: SitemapComponent
  },
  {
    path: 'search',
    component: SearchComponent
  },
  {
    path: 'search/:searchText',
    component: SearchComponent
  },
  {
    path: 'flow-post-its/:firstlevel/:secondlevel/:thirdlevel',
    component: FlowPostItsComponent
  },
  {
    path: 'flow-post-its/:firstlevel/:secondlevel',
    component: FlowPostItsComponent
  },
  {
    path: 'flow-post-its/:firstlevel',
    component: FlowPostItsComponent
  },
  {
    path: 'flow-post-its',
    component: FlowPostItsComponent
  },
  {
    path: 'protocols/:firstlevel/:secondlevel/:thirdlevel',
    component: ProtocolsComponent
  },
  {
    path: 'protocols/:firstlevel/:secondlevel',
    component: ProtocolsComponent
  },
  {
    path: 'protocols/:firstlevel',
    component: ProtocolsComponent
  },
  {
    path: 'protocols',
    component: ProtocolsComponent
  },
  {
    path: 'sort-calculator',
    component: SortCalculatorComponent
  },

  {
    path: 'facility-policy/:firstlevel/:secondlevel/:thirdlevel',
    component: FacilityPolicyComponent
  },
  {
    path: 'facility-policy/:firstlevel/:secondlevel',
    component: FacilityPolicyComponent
  },
  {
    path: 'facility-policy/:firstlevel',
    component: FacilityPolicyComponent
  },
  {
    path: 'facility-policy',
    component: FacilityPolicyComponent
  },
  {
    path: 'online-tools',
    component: OnlineToolsComponent
  },
  {
    path: 'sorting/:firstlevel/:secondlevel/:thirdlevel',
    component: SortingComponent
  },
  {
    path: 'sorting/:firstlevel/:secondlevel',
    component: SortingComponent
  },
  {
    path: 'sorting/:firstlevel',
    component: SortingComponent
  },
  {
    path: 'sorting',
    component: SortingComponent
  },
  {

  path: 'analysis/:firstlevel/:secondlevel/:thirdlevel',
    component: AnalysisComponent
  },
  {
    path: 'analysis/:firstlevel/:secondlevel',
    component: AnalysisComponent
  },
  {
    path: 'analysis/:firstlevel',
    component: AnalysisComponent
  },
  {
    path: 'analysis',
    component: AnalysisComponent
  },

  {
    path: 'analysis-software/:firstlevel/:secondlevel/:thirdlevel',
    component: AnalysisSoftwareComponent
  },
  {
    path: 'analysis-software/:firstlevel/:secondlevel',
    component: AnalysisSoftwareComponent
  },
  {
    path: 'analysis-software/:firstlevel',
    component: AnalysisSoftwareComponent
  },
  {
    path: 'analysis-software',
    component: AnalysisSoftwareComponent
  },

  {
    path: 'rates/:firstlevel/:secondlevel/:thirdlevel',
    component: RatesComponent
  },
  {
    path: 'rates/:firstlevel/:secondlevel',
    component: RatesComponent
  },
  {
    path: 'rates/:firstlevel',
    component: RatesComponent
  },
  {
    path: 'rates',
    component: RatesComponent
  },

  {
    path: 'servicing-individual-labs/:firstlevel/:secondlevel/:thirdlevel',
    component: ServicingIndividualLabsComponent
  },
  {
    path: 'servicing-individual-labs/:firstlevel/:secondlevel',
    component: ServicingIndividualLabsComponent
  },
  {
    path: 'servicing-individual-labs/:firstlevel',
    component: ServicingIndividualLabsComponent
  },
  {
    path: 'servicing-individual-labs',
    component: ServicingIndividualLabsComponent
  },
  {
    path: 'core-facility-related/:firstlevel/:secondlevel/:thirdlevel',
    component: CoreFacilityRelatedComponent
  },
  {
    path: 'core-facility-related/:firstlevel/:secondlevel',
      component: CoreFacilityRelatedComponent
  },
  {
    path: 'core-facility-related/:firstlevel',
      component: CoreFacilityRelatedComponent
  },
  {
    path: 'core-facility-related',
      component: CoreFacilityRelatedComponent
  }

];
