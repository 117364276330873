export const BIOSAFETY = 1;
export const CANCER_CENTER_SUPPORT = 2;
export const EDUCATION = 3;
export const FOR_OPERATORS = 4;
export const GOOD_FLOW_NOTES = 5;
export const GUIDELINES = 6;
export const INSTRUMENTS = 7;
export const LAB_LOCATIONS = 8;
export const NEWS_EVENTS = 9;
export const NEW_USER = 10;
export const PANEL_DESIGN_RESOURCES = 11;

export const SAMPLE_PREPARATION = 12;//not used

export const SEARCH = 48; 

export const SCIENCE = 13;
export const SERVICES = 14;
export const TEAM = 15;
export const TECHNOLOGY = 16;
export const TRAINING = 17;
export const TROUBLESHOOTING = 18;
export const ABOUT_US = 19;
export const CONTACT_US = 20;

export const LEGAL_DISCLAIMER = 21; //not used

export const PRIVACY_POLICY = 22;

export const PUBLIC_NOTICES = 23;//not used
export const SITEMAP = 24;// not used

export const MISSION = 25;
export const ACKNOWLEDGING_THE_FACILITY = 26;
export const FLOW_POST_ITS = 27;
export const PROTOCOLS = 28;
export const SORT_CALCULATOR = 29; //not used
export const FACILITY_POLICY = 30;
export const ONLINE_TOOLS = 31;

export const ANALYZERS = 35;
export const CELL_SORTERS = 36;

export const SORTING = 37;
export const ANALYSIS = 38;
export const ANALYSIS_SOFTWARE = 39;
export const PANEL_DESIGN = 40; //?
export const RATES = 41;
export const SERVICING_INDIVIDUAL_LABS = 42;

export const TRAINING_CLASSESS = 43;
export const TRAINING_ON_ANALYZERS = 44;
export const TRAINING_ON_CELL_SORTERS = 45;
export const TRAINING_WEBINAR_SERIES = 46;
export const TRAINING_EXCYTE_MASTERY = 47;
export const CORE_FACILITY_RELATED = 50;

export const BIOSAFETY_TITLE = "Biosafety";
export const CANCER_CENTER_SUPPORT_TITLE = "Cancer Center Support Grant";
export const EDUCATION_TITLE = "Education";
export const FOR_OPERATORS_TITLE = "For Operators";
export const GOOD_FLOW_NOTES_TITLE = "Good Flow Notes";
export const GUIDELINES_TITLE = "Instruments Guidelines";
export const INSTRUMENTS_TITLE = "Instruments";
export const LAB_LOCATIONS_TITLE = "Lab Locations";
export const NEWS_EVENTS_TITLE = "Events/News";
export const NEW_USER_TITLE = "New User";
export const PANEL_DESIGN_TITLE = "Panel Design";
export const SAMPLE_PREPARATION_TITLE = "Sample Preparation Title";
export const SCIENCE_TITLE = "Science";
export const SERVICES_TITLE = "Services";
export const TEAM_TITLE = "Team";
export const TECHNOLOGY_TITLE = "Technology";
export const TRAINING_TITLE = "Training";
export const TROUBLESHOOTING_TITLE = "Troubleshooting";
export const ABOUT_US_TITLE = "About us";
export const CONTACT_US_TITLE = "Contact us";
export const LEGAL_DISCLAIMER_TITLE = "Legal Disclaimer";
export const PRIVACY_POLICY_TITLE = "Privacy Policy";
export const PUBLIC_NOTICES_TITLE = "Public Noticies";
export const SITEMAP_TITLE = "Sitemap";
export const FLOW_POST_ITS_TITLE = "Flow Post-its";
export const PROTOCOLS_TITLE = "Protocols";
export const SORT_CALCULATOR_TITLE = "Sort Calculator";
export const FACILITY_POLICY_TITLE = "Facility Policy";
export const ONLINE_TOOLS_TITLE = "Online Tools";
export const ANALYZERS_TITLE = "Analyzers";
export const CELL_SORTERS_TITLE = "Cell Sorters";
export const CORE_FACILITY_RELATED_TITLE = "Core Facility Related";

export const SEARCH_TITLE = "Search";
