import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../shared/material.module';
import { SharedModule } from '../shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import {
  BiosafetyComponent,
  CancerCenterSupportComponent,
  EducationComponent,
  ForOperatorsComponent,
  GoodFlowNotesComponent,
  GuidelinesComponent,
  InstrumentsComponent,
  LabLocationsComponent,
  NewsEventsComponent,
  NewUserComponent,
  PanelDesignComponent,
  SamplePreparationComponent,
  ScienceComponent,
  ServicesComponent,
  TeamComponent,
  TechnologyComponent,
  TrainingComponent,
  TroubleshootingComponent,
  AboutUsComponent,
  ContactUsComponent,
  LegalDisclaimerComponent,
  PrivacyPolicyComponent,
  PublicNoticesComponent,
  SitemapComponent,
  SearchComponent,
  FlowPostItsComponent,
  ProtocolsComponent,
  SortCalculatorComponent,
  FacilityPolicyComponent,
  OnlineToolsComponent
} from './component.index';

import { ComponentRoutes } from './component.routes';
import { AnalyzersComponent } from './analyzers/analyzers.component';
import { CellSortersComponent } from './cell-sorters/cell-sorters.component';
import { SortingComponent } from './sorting/sorting.component';
import { AnalysisComponent } from './analysis/analysis.component';
import { AnalysisSoftwareComponent } from './analysis-software/analysis-software.component';
import { RatesComponent } from './rates/rates.component';
import { ServicingIndividualLabsComponent } from './servicing-individual-labs/servicing-individual-labs.component';
import { PanelDesignResourcesComponent } from './panel-design-resources/panel-design-resources.component';
import { TrainingClassesComponent } from './training-classes/training-classes.component';
import { TrainingOnAnalyzersComponent } from './training-on-analyzers/training-on-analyzers.component';
import { TrainingOnCellSortersComponent } from './training-on-cell-sorters/training-on-cell-sorters.component';
import { TrainingWebinarSeriesComponent } from './training-webinar-series/training-webinar-series.component';
import { TrainingExcyteMasteryComponent } from './training-excyte-mastery/training-excyte-mastery.component';
import { CoreFacilityRelatedComponent } from './core-facility-related/core-facility-related.component';

@NgModule({
  declarations: [
    BiosafetyComponent,
    CancerCenterSupportComponent,
    EducationComponent,
    ForOperatorsComponent,
    GoodFlowNotesComponent,
    GuidelinesComponent,
    InstrumentsComponent,
    LabLocationsComponent,
    NewsEventsComponent,
    NewUserComponent,
    PanelDesignComponent,
    SamplePreparationComponent,
    ScienceComponent,
    ServicesComponent,
    TeamComponent,
    TechnologyComponent,
    TrainingComponent,
    TroubleshootingComponent,
    AboutUsComponent,
    ContactUsComponent,
    LegalDisclaimerComponent,
    PrivacyPolicyComponent,
    PublicNoticesComponent,
    SitemapComponent,
    SearchComponent,
    FlowPostItsComponent,
    ProtocolsComponent,
    SortCalculatorComponent,
    FacilityPolicyComponent,
    OnlineToolsComponent,
    AnalyzersComponent,
    CellSortersComponent,
    SortingComponent,
    AnalysisComponent,
    AnalysisSoftwareComponent,
    RatesComponent,
    ServicingIndividualLabsComponent,
    PanelDesignResourcesComponent,
    TrainingClassesComponent,
    TrainingOnAnalyzersComponent,
    TrainingOnCellSortersComponent,
    TrainingWebinarSeriesComponent,
    TrainingExcyteMasteryComponent,
    CoreFacilityRelatedComponent
  ],
  imports: [
    BrowserModule,
    FontAwesomeModule,
    RouterModule.forChild(ComponentRoutes),
    CommonModule,
    FormsModule,
    MaterialModule,
    SharedModule
  ]
})
export class ComponentModule { }
