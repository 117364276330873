<footer class="footer p-4">
  <div class="container">
    <div class="content is-size-7">
      <div class="columns">
        <div class="column has-text-left">
          <p>
            Connect with us:
            <fa-icon [icon]="faPhone" class="mr-1"></fa-icon>646-888-3983
            <a [routerLink]="['/lab-locations']" class="ml-2">
              <fa-icon [icon]="faMapMarker"></fa-icon>
              Locations
            </a>
          </p>
          <!-- <br/> -->
          <p>
            <a [routerLink]="['/about-us']">
              About us
            </a> |
            
            <a [routerLink]="['/contact-us']">
              Contact us
            </a>
          </p>
        </div>
        <div class="column has-text-centered p-0">
          <!--<figure class="image">-->
            <img src="../../assets/images/MSKCC_logo_hor_s_rev_rgb_150.png" class="mt-3" style="height:80px">
          <!--</figure>-->
        </div>
        <div class="column has-text-right" style="margin-top: -33px;">
          <p class="mb-0 is-size-5">
            <a href="https://www.facebook.com/profile.php?id=100086025943566" title="Facebook" target="_blank" class="mr-2">
              <fa-icon [icon]="faFacebook"></fa-icon>
            </a>
            <a href="https://twitter.com/FlowMskcc" title="Twitter" target="_blank" class="mr-2">
              <!-- <fa-icon [icon]="faTwitter" class="fa-brands fa-x-twitter"></fa-icon> -->
              <img style="height:60px; padding-top:43px; padding-left:0.1em;" src="../../assets/images/x-twitter.png">
            </a>
            <a href="https://www.instagram.com/flowmskcc/?igsh=MW10ZmtreWJjYWZydQ%3D%3D" title="Instagram" class="mr-2">
              <fa-icon [icon]="faInstagram"></fa-icon>
            </a>
            <a href="https://www.youtube.com/flowmskcc" title="YouTube" target="_blank" class="mr-2">
              <fa-icon [icon]="faYoutube"></fa-icon>
            </a>
            <a href="https://www.linkedin.com/in/flowmskcc" title="Linkedin" target="_blank">
              <fa-icon [icon]="faLinkedin"></fa-icon>
            </a>
            <a style="padding-left:1px !important;" title="Bilibili" href="https://space.bilibili.com/1485336809?spm_id_from=333.337.search-card.all.click" target="_blank">
              <img style="height:50px; padding-top:33px; padding-left:0.4em;" src="../../assets/images/bilibili-gray.png">
              <!--<fa-icon class="fa-brands fa-bilibili"></fa-icon>-->
            </a>

          </p>
         
          <p class="mb-0">
            <!-- <a [routerLink]="['/legal-disclaimer']">
              Legal disclaimer
            </a> |
            <a [routerLink]="['/privacy-policy']">
              Privacy policy
            </a> |
            <a [routerLink]="['/public-notices']">
              Public notices
            </a> |
            <a [routerLink]="['/sitemap']">
              Sitemap
            </a> -->
          </p>
          <p>
            © 2020 Memorial Sloan Kettering Cancer Center
          </p>
        </div>
      </div>
    </div>
  </div>
</footer>
