<section class="content section">
  <div class="container" *ngIf="page">
    <h3 class="title">
      {{page.title}}
    </h3>
    <div class="m-b-sm m-t-sm" *ngIf="page.notification">
      <div class="ql-editor notification" [innerHTML]="page.notification | safeHtml">
      </div>
    </div>
    <div class="m-b-sm m-t-sm" *ngIf="page.description">
      <div class="editor ql-editor" [innerHTML]="page.description | safeHtml">
      </div>
    </div>
    <!--<div class="m-b-sm m-t-sm">
      <accordion *ngIf="page.content_display_type.display_type_uid == contentDisplayType.ACCORDION" [contents]="contents"
                 [contentTemplate]="contentTemplate" [loadContentTemplate]="true"
                 [firstLevel]="firstLevel" [secondLevel]="secondLevel" [thirdLevel]="thirdLevel"></accordion>

      <tabs *ngIf="page.content_display_type.display_type_uid == contentDisplayType.TABS" [contents]="contents"
            [selectedTitle]="selectedTitle" [contentTemplate]="contentTemplate"
            [loadContentTemplate]="true"></tabs>
    </div>-->


    <div class="m-b-sm m-t-sm">
      <accordion [contents]="contents" [firstLevel]="firstLevel" [secondLevel]="secondLevel" [thirdLevel]="thirdLevel" [fourthLevel]="fourthLevel"></accordion>
    </div>
  </div>
</section>

<ng-template #contentTemplate let-content="content">
  <div [innerHTML]="content.description | safeHtml">
  </div>
</ng-template>
