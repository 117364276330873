import { Component, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";

import SharedService from '../../shared/shared.service';
import { INav, IPage, ISlider,  ISliderData,  ISliderObject,IImage } from '../../shared/model/model.index';

import { NEWS_EVENTS, MISSION, ACKNOWLEDGING_THE_FACILITY } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';


import {
  IconDefinition,
  faPlayCircle
} from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent extends BaseComponent implements OnInit {
  sliders: ISlider[];
  sliderObjects: ISliderObject[];

  _sharedService: SharedService;
  mission: IPage;
  ackFacility: IPage;
  public faPlay: IconDefinition = faPlayCircle;

  constructor(sharedService: SharedService,
    private titleService: Title,
    route: ActivatedRoute, router: Router) {
    super(sharedService, route, router);
    this.uid = NEWS_EVENTS;
    this._sharedService = sharedService;
    this.titleService.setTitle("Flow Cytometry");
    this.getSliders();
    this.getData();
  }

  ngOnInit() {
    this.getContent('datetime:DESC');
  }

  getData(): void {

    this._sharedService.getPageByUID(MISSION).subscribe(p => {
      if (p) {

        const attr = p.data.attributes;
        const navP: INav = { created_at: "", id: 1, is_disabled: false, is_external: true, is_parent: false, name: "mission", published_at: "", sub_navs: null, updated_at: "", url: "mission" }

        const thisPage: IPage = {
          id: p.data.id, content_display_type: null,
          created_at: attr.createdAt, published_at: attr.publishedAt, updated_at: attr.updatedAt,
          title: attr.title,
          description: attr.description,
          notification: attr.notification,
          is_active: attr.is_active,
          uid: attr.uid,
          nav: navP,
          url: attr.url
        }

        this.mission = thisPage;

      }
    });

    this._sharedService.getPageByUID(ACKNOWLEDGING_THE_FACILITY).subscribe(p => {
      if (p) {

        const attr = p.data.attributes;
        const navP: INav = { created_at: "", id: 1, is_disabled: false, is_external: true, is_parent: false, name: "mission", published_at: "", sub_navs: null, updated_at: "", url: "mission" }

        const thisPage: IPage = {
          id: p.data.id, content_display_type: null,
          created_at: attr.createdAt, published_at: attr.publishedAt, updated_at: attr.updatedAt,
          title: attr.title,
          description: attr.description,
          notification: attr.notification,
          is_active: attr.is_active,
          uid: attr.uid,
          nav: navP,
          url:attr.url
        }

        this.ackFacility = thisPage;

      }

    });

  }

  getSliders(): void {
    this._sharedService.getSliders().subscribe((resp: ISliderObject) => {
      const items: ISliderData[] = resp.data;



      const allSliders = items.map(x => {

        const iimgg: IImage = {
          alternativeText: x.attributes.image.data.attributes.alternativeText,
          caption: x.attributes.image.data.attributes.caption,
          created_at: '', updated_at: '',
          ext: '',
          formats: x.attributes.image.data.attributes.formats,
          hash: '',
          height: x.attributes.image.data.attributes.height,
          id: x.id,
          mime: '',
          name: x.attributes.image.data.attributes.name,
          previewUrl: '',
          provider: '',
          provider_metadata: '',
          size: x.attributes.image.data.attributes.size,
          url: x.attributes.image.data.attributes.url,
          width: x.attributes.image.data.attributes.width
          
        };



        const s: ISlider = {
          id: x.id,
          is_active: x.attributes.is_active,
          is_external: x.attributes.is_external,
          order: x.attributes.order,
          url: x.attributes.url,
          updatedAt: '',
          createdAt: '',
          publishedAt: '',
          image: iimgg

        };
        return s;
      });
      
      this.sliders = allSliders.filter(i => i.image !== null);
    });

  }
}
