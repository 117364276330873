import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";

import SharedService from '../../shared/shared.service';
import { SITEMAP, SITEMAP_TITLE } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: []
})
export class SitemapComponent extends BaseComponent {

  constructor(sharedService: SharedService
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {
    super(sharedService, route, router);
    this.uid = SITEMAP;
    this.titleService.setTitle(SITEMAP_TITLE);
  }

  selectTab(tabName: string) {
    this.selectedTitle = tabName;
  }
}
